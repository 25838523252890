import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GenerateAvatar from "../../../shared/components/GenerateAvatar";
import {
  capitalizeFirst,
  monthStringFormatDate,
} from "../../../utils/extensions";
import Dropdown from "../../../shared/components/Dropdown";
import { createStyles, makeStyles } from "@mui/styles";

type Props = {
  data: any;
};

const ReportCard = (props: Props) => {
  const styles = useStyles();
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    if (props?.data?.status) {
      setStatus(capitalizeFirst(props?.data?.status));
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "14px",
        bgcolor: "#141414",
        padding: "24px 31px 24px 24px",
        gap: "16px",
      }}
    >
      {/* -------Header Section------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "24px",
        }}
      >
        {/* ----Profile Section---- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {props?.data?.userId?.avatar ? (
            <img
              style={{
                width: "60px",
                height: "60px",
                aspectRatio: "1/1",
                borderRadius: "50%",
              }}
              alt="profile"
              src={props?.data?.userId?.avatar || ""}
            />
          ) : (
            <GenerateAvatar
              width="60px"
              height="60px"
              text={
                props?.data?.userId?.firstName
                  ? props?.data?.userId?.firstName[0] +
                    props?.data?.userId?.lastName[0]
                  : "UN"
              }
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              textAlign: "start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                color: "white",
              }}
              variant="body1"
            >
              {props?.data?.userId?.firstName + props?.data?.userId?.lastName}
            </Typography>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
              variant="body2"
            >
              Feedback sumitted on{" "}
              {monthStringFormatDate(props?.data?.createdAt)}
            </Typography>
          </Box>
        </Box>

        {/* ----Report Status---- */}
        <Box>
          <Dropdown
            className={styles.dropdown}
            classDropdownPlaceholder={styles.placeholder}
            classDropdownValueText={styles.dropdownValueText}
            dropdownOptionsBodyStyles={{
              fontWeight: "300",
              fontSize: "16px",
            }}
            value={status}
            placeholder="Select"
            setValue={setStatus}
            options={["In issue", "Resolved"]}
          />
        </Box>
      </Box>

      {/* -----Report Description----- */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "start",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "white",
            lineHeight: "100%",
          }}
          variant="body1"
        >
          {props?.data?.title}
        </Typography>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            lineHeight: "100%",
          }}
          variant="body2"
        >
          {" "}
          {props?.data?.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReportCard;

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "132px",
      maxWidth: "132px",
      height: "46px",
    },
    placeholder: {
      color: "#A0AEC0",
      fontSize: "16px",
      fontWeight: "300 !important",
      fontFamily: "Outfit",
    },
    dropdownValueText: {
      color: "white",
      fontSize: "16px",
      fontWeight: "300",
    },
  })
);
