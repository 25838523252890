import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
// import socket from "../../../socket";
import GenerateAvatar from "../../shared/components/GenerateAvatar";


const User = ({ className, userDetails }) => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const LOGOUT = "Log out";
  const items = [
    // {
    //   menu: [
    //     // {
    //     //   title: "Profile",
    //     //   url: `/teamManagement/myProfile/${userDetails?.id}`,
    //     //   navigationRef: "My Profile",
    //     //   subNavigationRef:
    //     //     userDetails?.employeeProfile?.firstName +
    //     //     " " +
    //     //     userDetails?.employeeProfile?.lastName,
    //     // },
    //   ],
    // },
    {
      menu: [
        // {
        //   title: "Account settings",
        //   url: "/settings",
        //   navigationRef: "Settings",
        // },
        {
          title: LOGOUT,
        },
      ],
    },
  ];

  const navigate = useNavigate();
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={styles.headUser}
          onMouseEnter={() => setVisible(true)}
          onMouseDown={() => setVisible(false)}
          onClick={() => setVisible(!visible)}
        >
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={styles.head}
          >
            {userDetails?.avatar ? (
              <img
                src={userDetails?.avatar}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "40px",
                  height: "40px",
                }}
                alt="Profile"
                height="100%"
                width="100%"
              ></img>
            ) : (
              <GenerateAvatar
                width="40px"
                height="40px"
                text={
                  userDetails
                    ? userDetails?.employeeProfile?.firstName[0] +
                    userDetails?.employeeProfile?.lastName[0]
                    : "UN"
                }
              />
            )}
          </Box>
        </button>

        <Box onMouseLeave={() => setVisible(false)} className={styles.body}>
          {items?.map((item, index) => {
            return (
              <Box className={styles.menu} key={index}>
                {item?.menu?.map((x, i) => {
                  return x.url ? (
                    <NavLink
                      className={cn(styles.item, {
                        [styles.color]: x.color,
                        [styles.active]: pathname === x.url,
                      })}
                      to={x.url}
                      onClick={() => {
                        setVisible(false);
                      }}
                      key={i}
                    >
                      {/* {x.icon && (
                      <img
                        style={{
                          width: "52px",
                          height: "32px",
                        }}
                        alt="profile"
                        src={DefaultAvatar}
                      />
                    )} */}
                      {x.title}
                    </NavLink>
                  ) : (
                    <Typography
                      sx={{
                        marginLeft: "12px",
                        cursor: "pointer",
                      }}
                      color="red"
                      onClick={() => {
                        if (x.title === LOGOUT) {
                          Cookies.remove("jstoken");
                          // socket.emit("send_userId", {
                          //   userId: userDetails?.id,
                          // });
                          // socket.emit("send_userId_to_mark_user_online", {
                          //   userId: userDetails?.id,
                          // }); //emitting user id to frontend
                          window.location.reload();
                        }
                      }}
                    >
                      {x.title}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
