import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { toastSuccess } from "../../utils/ToastMessage";
import { FigmaIcon } from "../../assets/icons/icons";
import { LockIconSvg } from "../../assets/icons/LockIconSvg";
import Chip from "../../shared/components/Chip";
import ReportCard from "./components/ReportCard";
import { useEffect, useState } from "react";
import { getAllReports, getComponentById } from "../../services/component";
import React from "react";

type Props = {};

const ReportDeailScreen = (props: Props) => {
  const tableRef = React.createRef<any>();
  const location = useLocation();
  const { component } = location.state || {}; // Retrieve id from location state

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loading, setLoading] = useState<boolean>(true);
  const [componentDetails, setComponentDetails] = useState<any>("");
  const [reportsList, setReportsList] = useState<any>([]);

  const handleCopyFigmaCode = () => {
    const htmlContent = component?.figmaCode;
    // Create a Blob with the HTML content and specify the MIME type as 'text/html'
    const blob = new Blob([htmlContent], { type: "text/html" });
    const clipboardItem = new ClipboardItem({ "text/html": blob });
    // Copy the Blob to the clipboard
    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        toastSuccess("Successfull", "Component is copied");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const getComponentReports = async (filters: any) => {
    await getAllReports(filters)
      .then((reports: any) => {
        setReportsList(reports);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getComponentDetails = async () => {
    await getComponentById(component?.id)
      .then((res: any) => {
        setComponentDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    const newFilterList = {
      componentId: component.id,
      page: newPage + 1,
      pageSize: rowsPerPage,
    };
    getComponentReports(newFilterList);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    tableRef.current && tableRef.current.scrollIntoView();
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (component?.id) {
      setLoading(true);
      getComponentReports({
        componentId: component?.id,
        page: page + 1,
        pageSize: rowsPerPage,
      });
      getComponentDetails();
    }
  }, [component]);

  useEffect(() => {
    if (componentDetails && reportsList) {
      setLoading(false);
    }
  }, [componentDetails, reportsList]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {/* Main Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "40px 80px 80px 80px",
        }}
      >
        {/* ------Content detail section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Typography sx={{ color: "white", fontWeight: "600" }} variant="h4">
              {component?.title || componentDetails?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: "400" }}
            >
              {component?.blurb || componentDetails?.blurb}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "38px",
            }}
          >
            {/* ----Preview Image---- */}

            <Box
              sx={{
                width: "fit-content",
                height: "fit-content",
                flex: 1,
                flexDirection: "column",
                display: "flex",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  borderRadius: "14px",
                  bgcolor: "#1B1B1B",
                  aspectRatio: "3/2",
                }}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{ color: "white", width: "24px", height: "24px" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      borderRadius: "14px",
                      backgroundImage: componentDetails?.documents[0]
                        ? `url(${componentDetails?.documents[0]?.previewURL})`
                        : "",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      aspectRatio: "3/2",
                    }}
                  ></Box>
                )}
              </Box>

              {/* ------Tags------ */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {componentDetails?.tags?.map((tag: string, index: number) => {
                  return <Chip index={index} title={tag} />;
                })}
              </Box>
            </Box>

            {/* -----Details----- */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxWidth: "338px",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              {/* ---Figma--- */}
              <Box
                onClick={(e: any) => {
                  e.preventDefault();
                  if (Cookies.get("jstoken")) {
                    handleCopyFigmaCode();
                  }
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "15px",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <ImageContainer
                    style={{ width: "24px", height: "24px" }}
                    title="figma"
                  >
                    {FigmaIcon}
                  </ImageContainer>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: "400",
                    }}
                  >
                    Figma
                  </Typography>
                </Box>
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <LockIconSvg />
                </Box>
              </Box>

              {/* ---Overview--- */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "24px",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Overview
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                  variant="body1"
                >
                  {componentDetails?.description}
                </Typography>
              </Box>

              {/* ------Product Description------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  bgcolor: "#1B1B1B",
                  boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                  borderRadius: "14px",
                  justifyContent: "space-between",
                  padding: "24px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Product Description
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Category
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Content
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Release
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      May 6, 2024
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      Author
                    </Typography>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "400",
                      }}
                      variant="body1"
                    >
                      UI Wiki
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* ------Reports------ */}
          {!loading ? (
            reportsList?.paginatedProblems?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "24px",
                }}
              >
                {reportsList?.paginatedProblems?.map((report: any) => {
                  return <ReportCard data={report} />;
                })}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TablePagination
                    labelRowsPerPage="Showing"
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      style: {
                        fontWeight: "700",
                      },
                    }}
                    sx={{
                      "& .MuiTablePagination-selectLabel": {
                        fontWeight: "700",
                        color: "rgba(255, 255, 255, 0.24)",
                        marginRight: "5px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        color: "rgba(255, 255, 255, 0.24)",
                        fontWeight: "600",
                      },
                      "& .MuiButtonBase-root": {
                        boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                        cursor: "pointer !important",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      "& .MuiTablePagination-actions": {
                        display: "flex",
                        gap: "12px !important",
                        flex: 1,
                        justifyContent: "flex-end",
                      },
                      "&.MuiTablePagination-root": {
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      },
                      "& .MuiTablePagination-spacer": {
                        flex: 0,
                        display: "none",
                      },
                      "& .MuiTablePagination-toolbar": {
                        flex: 1,
                        display: "flex",
                        paddingLeft: 0,
                      },
                      "& .MuiTablePagination-select": {
                        display: "none",
                      },
                      "& .MuiInputBase-root": {
                        display: "none",
                      },
                    }}
                    // rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={reportsList?.totalProblems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            ) : (
              <Typography color="white">No Reports yet</Typography>
            )
          ) : (
            <CircularProgress
              style={{
                color: "white",
                width: "24px",
                height: "24px",
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportDeailScreen;
