import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../shared/components/TextInput";
import { createStyles, makeStyles } from "@mui/styles";
import TextAreaInput from "../../../shared/components/TextAreaInput";
import { ImageIconSvg } from "../../../assets/icons/ImageIconSvg";
import TagInput from "../../../shared/components/tags/Tags";
import { ButtonSecondary } from "../../../shared/components/button/Buttons";
import AddIcon from "@mui/icons-material/Add";
import { useGlobalContext } from "../../../App";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAsDraftDialog from "./SaveAsDraftDialog";
import Cookies from "js-cookie";
import { getLocalStorageItem } from "../../../utils/localstorage";
import { CrossIconSvg } from "../../../assets/icons/CrossIconSvg";

type Props = {};

const AddComponent = (props: Props) => {
  const dropZoneRef = useRef<DropzoneRef | null>(null);
  const styles = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    componentDetailsList,
    setComponentDetailsList,
    formComplete,
    setFormComplete,
    activeSaveAsDraftDialog,
    setActiveSaveAsDraftDialog,
  } = useGlobalContext();

  const productComponentObject = {
    title: "",
    blurb: "",
    description: "",
    documents: [],
    tags: [],
    figmaCode: "",
    status: "",
  };

  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const handlePaste = async (event: any, id: any) => {
    event.preventDefault(); // Prevent the default paste behavior

    try {
      const html = event.clipboardData.getData("text/html");

      const updatedList = componentDetailsList?.map((obj: any, index: any) => {
        if (index === id) {
          return { ...obj, figmaCode: html };
        }
        return obj;
      });

      setComponentDetailsList(updatedList);

      // setHtmlContent(html || "No HTML content");
    } catch (err) {
      // setHtmlContent("Failed to read HTML content");
      console.error("Failed to read clipboard: ", err);
    }
  };

  const handleChangeTitle = async (id: any, title: string) => {
    setFormComplete(false);
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, title: title };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const handleChangeBlurb = async (id: any, title: string) => {
    setFormComplete(false);
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, blurb: title };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const handleChangeDescription = async (id: any, text: string) => {
    setFormComplete(false);
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, description: text };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const handleChangeThumbnail = async (id: any, file: any) => {
    setFormComplete(false);
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, documents: file };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const handleRemoveThumbnail = (id: any) => {
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, documents: [] };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const onFileDrop = async (id: any, file: any) => {
    handleChangeThumbnail(id, file);
  };

  const handleChangeTags = (id: any, tags: any) => {
    setFormComplete(false);
    const updatedList = componentDetailsList?.map((obj: any, index: any) => {
      if (index === id) {
        return { ...obj, tags: tags };
      }
      return obj;
    });

    setComponentDetailsList(updatedList);
  };

  const handleCloseSaveAsDraftDialog = (res: any) => {
    if (res === "SAVE_AS_DRAFT") {
      // Cookies.set(
      //   "ACTIVE_PRODUCT_COMPONENT_FORM_DRAFT",
      //   JSON.stringify(componentDetailsList)
      // );
      setFormComplete(true);
      const navigateTo = getLocalStorageItem("NavigateTo");
      navigate(`${navigateTo}`);
    }
    if (res === "CLOSE_WITHOUT_SAVE") {
      setFormComplete(true);
      const navigateTo = getLocalStorageItem("NavigateTo");
      navigate(`${navigateTo}`);
    }
    setActiveSaveAsDraftDialog(false);
  };

  useEffect(() => {
    const beforeUnloadHandler = (e: any) => {
      if (!formComplete) {
        e.preventDefault();
        e.returnValue = ""; // Required for Chrome
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [formComplete]);

  useEffect(() => {
    const type = window.location.pathname?.split("/")[3];
    if (type === "edit") {
      setIsEditForm(true);
      const data = location.state?.data || [];
      setComponentDetailsList(data);
    } else {
      const savedDataString: any = Cookies.get(
        "ACTIVE_PRODUCT_COMPONENT_FORM_DRAFT"
      );
      if (savedDataString) {
        try {
          const savedData = JSON.parse(savedDataString);
          setComponentDetailsList(savedData);
        } catch (error) {
          console.error("Failed to parse saved data", error);
        }
      } else {
        setComponentDetailsList([productComponentObject]);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [componentDetailsList]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {activeSaveAsDraftDialog && (
        <SaveAsDraftDialog
          activeDialog={activeSaveAsDraftDialog}
          handleDialog={handleCloseSaveAsDraftDialog}
        />
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingY: "32px",
          gap: "24px",
        }}
      >
        {componentDetailsList?.map((data: any, index: any) => {
          return (
            <Box
              key={data?.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "980px",
                minWidth: "980px",
                width: "100%",
                bgcolor: "#141414",
                borderRadius: "14px",
                padding: "40px",
                gap: "24px",
              }}
            >
              {/* Row1 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "24px",
                  }}
                >
                  <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                    <TextInput
                      style={{ flex: 1 }}
                      label="Title"
                      lableStyles={{ fontSize: "16px !important" }}
                      placeholder="Enter the title"
                      type="text"
                      value={data?.title}
                      onChange={(e: any) => {
                        handleChangeTitle(index, e.target.value);
                      }}
                    />

                    <Typography
                      className="err_field"
                      id="createProductTitlebNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                    <TextInput
                      style={{ flex: 1 }}
                      label="Blurb"
                      lableStyles={{ fontSize: "16px !important" }}
                      placeholder="Short product description"
                      type="text"
                      value={data?.blurb}
                      onChange={(e: any) => {
                        handleChangeBlurb(index, e.target.value);
                      }}
                    />

                    <Typography
                      className="err_field"
                      id="createProductBlurbNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                  <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                    <TextAreaInput
                      style={{
                        flex: 1,
                        height: "172px",
                      }}
                      label="Description"
                      lableStyles={{ fontSize: "16px !important" }}
                      placeholder="Long product description"
                      type="text"
                      value={data?.description}
                      onChange={(e: any) => {
                        handleChangeDescription(index, e.target.value);
                      }}
                    />

                    <Typography
                      className="err_field"
                      id="createProductDescNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Thumbnail
                    </Typography>

                    <Box
                      sx={{
                        flex: 1,
                        aspectRatio: "430px/284px",
                        borderRadius: "14px",
                        border: "2px dashed #3D3D3D",
                        bgcolor: "rgba(255, 255, 255, 0.06)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {data?.documents?.length > 0 ? (
                        <Box
                          sx={{
                            width: "100%",
                            backgroundImage:
                              data?.documents[0] instanceof File
                                ? `url(${URL.createObjectURL(
                                    data.documents[0]
                                  )})`
                                : `url(${data?.documents[0]?.url})`,
                            flex: 1,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "14px",
                            position: "relative",
                            "&:hover .overlay": {
                              opacity: 1, // Make the overlay visible on hover
                            },
                          }}
                        >
                          <Box
                            className="overlay"
                            sx={{
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              backgroundColor: "rgba(0, 0, 0, 0.8)",
                              opacity: 0, // Start with the overlay hidden
                              transition: "opacity 0.3s ease", // Smooth transition effect
                              borderRadius: "14px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "16px",
                                gap: "9px",
                              }}
                            >
                              <Typography
                                color={"white"}
                                fontWeight={600}
                                variant="body1"
                              >
                                Remove
                              </Typography>
                              <Box
                                onClick={() => {
                                  handleRemoveThumbnail(index);
                                }}
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "75px",
                                  backgroundColor: "#E25454",
                                  cursor: "pointer",
                                }}
                              >
                                <CrossIconSvg color="white" />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Dropzone
                          accept={{
                            "image/png": [".png"],
                            "image/jpeg": [".jpeg", ".jpg"],
                          }}
                          ref={dropZoneRef}
                          disabled={false}
                          multiple={true}
                          onDrop={(acceptedFiles: any) => {
                            onFileDrop(index, acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flex: 1,
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "4px",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <ImageIconSvg />
                                <Typography
                                  sx={{ color: "text.secondary", mt: "10px" }}
                                  variant="subtitle1"
                                >
                                  Drag & drop image to upload.
                                </Typography>
                                <Typography
                                  sx={{ maxWidth: "70%", textAlign: "center" }}
                                  color={"rgba(255, 255, 255, 0.5)"}
                                  variant="body2"
                                >
                                  1208x840px size required in PNG or JPG format
                                  only
                                </Typography>
                              </Box>
                              {/* )} */}
                            </div>
                          )}
                        </Dropzone>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <TagInput
                      textinputstyles={{ maxWidth: "430px", minWidth: "100%" }}
                      value={data?.tags || []}
                      onChange={(newTags: any) => {
                        handleChangeTags(index, newTags);
                      }}
                      label="Tags (Maximum 5)"
                    />
                    <Typography
                      className="err_field"
                      id="createProductTagsNotExist"
                      color="red"
                      variant="body2"
                    ></Typography>
                  </Box>
                </Box>
              </Box>

              {/* Row2 */}
              <Box>
                <Box sx={{ flex: 1 }} className={styles.inputContainer}>
                  <TextAreaInput
                    style={{ flex: 1, height: "172px" }}
                    label="Component Source"
                    lableStyles={{ fontSize: "16px !important" }}
                    placeholder="Paste your JSON code here..."
                    type="text"
                    value={data?.figmaCode}
                    onPaste={(e: any) => handlePaste(e, index)}
                  />

                  <Typography
                    className="err_field"
                    id="createComponentSourceNotExist"
                    color="red"
                    variant="body2"
                  ></Typography>
                </Box>
              </Box>
            </Box>
          );
        })}

        {!isEditForm && (
          <ButtonSecondary
            onClick={() => {
              setComponentDetailsList((oldData: any) => [
                ...oldData,
                productComponentObject,
              ]);
            }}
            LabelStyle={{ fontSize: "16px !important" }}
            startIcon={
              <AddIcon sx={{ color: "white", width: "24px", height: "24px" }} />
            }
            label="Add new component"
          />
        )}
      </Box>
    </Box>
  );
};

export default AddComponent;

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    placeholder: {
      color: "#687588",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter Tight",
    },
    inputContainer: {
      flex: 1,
    },
    field: {
      // width: "100%",
      // maxWidth: "100%",
    },
  })
);
