import { Box, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import LayoutTable from "./layout/Table";
import { useGlobalContext } from "../../App";
import { getAllComponents } from "../../services/component";
import Search from "../../shared/components/Search";
import { FilterIconSvg } from "../../assets/icons/FilterIconSvg";
import ActionCard from "../../shared/components/ActionCard";

type Props = {};

export interface ActionMenuProps {
  title: string;
  action: () => void;
  actionTitle: string;
}

const tableRef = React.createRef<any>();

const FeedbacksList = (props: Props) => {
  const { showSelectedRowsMenuFooter } = useGlobalContext();
  const columnItems = [
    { id: "Name", label: "Name" },
    { id: "Number of feedback", label: "Number of feedback" },
    { id: "Component Status", label: "Component Status" },
  ];

  // current page and rows per page handling states and functions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedbacksList, setFeedbacksList] = useState<any>([]);

  // ROWS SELECTION AND ACTION CARD HANDLING STATES
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [actionCardProps, setActionCardProps] = useState<ActionMenuProps>();
  const [activeSelectedMenu, setActiveSelectedMenu] = useState(false);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const fetchFeedbacks = async (filters?: any) => {
    setLoading(true);
    await getAllComponents(filters)
      .then((res: any) => {
        setLoading(false);
        setFeedbacksList(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleRefreshData = () => {
    setPage(0);
    fetchFeedbacks({
      searchBy: search,
      page: page,
      pageSize: rowsPerPage,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    const newFilterList = {
      searchBy: search,
      page: newPage + 1,
      pageSize: rowsPerPage,
    };
    fetchFeedbacks(newFilterList);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    tableRef.current && tableRef.current.scrollIntoView();
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSelectedMenu = (value: any) => {
    setActionCardProps({
      title: value.title,
      action: value.action,
      actionTitle: value.actionTitle,
    });
  };

  useEffect(() => {
    fetchFeedbacks({
      searchBy: search,
      page: page,
      pageSize: rowsPerPage,
    });
  }, [search]);

  useEffect(() => {}, [feedbacksList]);

  useEffect(() => {
    setActiveSelectedMenu(false);
  }, []);

  useEffect(() => {
    setActiveSelectedMenu(showSelectedRowsMenuFooter);
  }, [showSelectedRowsMenuFooter]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingY: "32px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "85%",
          mb: "21px",
          gap: "12px",
        }}
      >
        <Box sx={{ display: "flex", height: "76px", flex: 1 }}>
          <Search
            searchFormStyles={{
              minHeight: "76px !important",
            }}
            style={{
              minHeight: "76px !important",
              color: "white",
              boxShadow: "none",
              paddingLeft: "76px",
            }}
            icon={true}
            iconStyles={{
              left: "40px",
            }}
            placeholder="Search"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "79px",
            height: "76px",
            borderRadius: "14px",
            bgcolor: "#141414",
            cursor: "pointer",
          }}
        >
          <FilterIconSvg />
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "85%",
              paddingBottom: activeSelectedMenu ? "70px" : "0px",
            }}
          >
            <LayoutTable
              loading={loading}
              rows={feedbacksList?.paginatedComponents}
              columns={columnItems}
              handleRefreshData={handleRefreshData}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              activeSelectedMenu={activeSelectedMenu}
              handleSelectedMenu={(values) => {
                handleChangeSelectedMenu(values);
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TablePagination
                labelRowsPerPage="Showing"
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  style: {
                    fontWeight: "700",
                  },
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "#3D3D3D",
                        "& .MuiMenuItem-root": {
                          color: "#ffff",
                          "&:hover": {
                            backgroundColor: "#555555",
                          },
                        },
                      },
                    },
                  },
                }}
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    fontWeight: "700",
                    color: "rgba(255, 255, 255, 0.24)",
                    marginRight: "5px",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    color: "rgba(255, 255, 255, 0.24)",
                    fontWeight: "600",
                  },
                  "& .MuiButtonBase-root": {
                    boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                    cursor: "pointer !important",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiTablePagination-actions": {
                    display: "flex",
                    gap: "12px !important",
                    flex: 1,
                    justifyContent: "flex-end",
                  },
                  "&.MuiTablePagination-root": {
                    width: "100%",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  },
                  "& .MuiTablePagination-spacer": {
                    flex: 0,
                    display: "none",
                  },
                  "& .MuiTablePagination-toolbar": {
                    flex: 1,
                    display: "flex",
                    paddingLeft: 0,
                  },
                  "& .MuiTablePagination-select": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  },
                  "& .MuiInputBase-input": {
                    color: "#ffff",
                  },
                  // To hide the rows per page dropdown
                  "& .MuiInputBase-root": {
                    // display: "none",
                  },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={feedbacksList?.totalComponents}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>

            {/* -------Multi select rows Action bar------- */}
            <Box
              sx={{
                position: "absolute",
                bottom: 20,
                left: "auto",
                right: "auto",
                width: "72.5%",
                zIndex: 10,
              }}
            >
              <ActionCard
                onNegativeAction={() => {}}
                negativeButtonText={"Delete"}
                headerText={actionCardProps?.title || "No items selected"}
                isActionCardVisible={activeSelectedMenu}
                onCloseButtonClick={() => {
                  setActiveSelectedMenu(false);
                  setSelectedRows([]);
                }}
                isNegativeButtonVisible={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbacksList;
