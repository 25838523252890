import { Box, Typography } from "@mui/material";
import React from "react";
import { CrossIconSvg } from "../../assets/icons/CrossIconSvg";
import { ButtonPrimary } from "./button/Buttons";
import { colors } from "../../utils/colors";

interface ActionCardProps {
  onPositiveAction?: () => void;
  onNegativeAction?: () => void;
  positiveButtonText?: string;
  negativeButtonText?: string;
  headerText: string | undefined;
  isActionCardVisible: boolean;
  isPositiveButtonVisible?: boolean;
  isNegativeButtonVisible?: boolean;
  onCloseButtonClick: () => void;
  isPositiveButtonLoading?: boolean;
  isNegativeButtonLoading?: boolean;
}

const ActionCard: React.FC<ActionCardProps> = (props: ActionCardProps) => {
  if (props?.headerText === "No items selected" && props?.isActionCardVisible) {
    props?.onCloseButtonClick();
  }

  // If the action card is not visible, return null (instead of false)
  if (!props?.isActionCardVisible) {
    return null;
  }
  return (
    props?.isActionCardVisible && (
      <Box
        sx={{
          minHeight: "76px",
        }}
        id="actionCard"
        paddingX={"32px"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        display={"flex"}
        width={"100%"}
        borderRadius={"16px"}
        paddingY={"12px"}
        bgcolor={"#1B1B1B"}
      >
        {/* Selected */}
        <Box className="center" gap={"9px"}>
          <Box
            sx={{
              cursor: "pointer !important",
              zIndex: "100",
            }}
            onClick={() => props?.onCloseButtonClick()}
          >
            <CrossIconSvg width="24" height="24" color="white" />
          </Box>
          <Typography color={"white"} fontSize={"14px"} fontWeight={"500"}>
            {props?.headerText}
          </Typography>
        </Box>
        {/* Actions */}
        <Box className="center" gap={"13px"}>
          {props?.isPositiveButtonVisible && props?.positiveButtonText && (
            <ButtonPrimary
              isLoading={props?.isPositiveButtonLoading ?? false}
              onClick={props?.onPositiveAction}
              label={props?.positiveButtonText}
            />
          )}
          {props?.isNegativeButtonVisible && props?.negativeButtonText && (
            <ButtonPrimary
              isLoading={props?.isNegativeButtonLoading ?? false}
              onClick={props?.onNegativeAction}
              sx={{
                color: "white",
                borderRadius: "14px",
                height: "42px",
                width: "100px !important",
                bgcolor: colors.primaryRed,
                ":hover": {
                  bgcolor: colors.primaryRed,
                },
              }}
              label={props?.negativeButtonText}
            />
          )}
        </Box>
      </Box>
    )
  );
};

export default ActionCard;
